"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RepositoryList = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var router_1 = require("@codebuild/uikit/libs/router/router");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var Dimmer_1 = require("semantic-ui-react/dist/commonjs/modules/Dimmer");
var RepositoryList = (function (_super) {
    __extends(RepositoryList, _super);
    function RepositoryList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cancelToken = repository_1.Repository.createCancelToken();
        _this.state = {
            items: [],
            response: null,
            loading: false,
            error: null,
            total: null,
        };
        return _this;
    }
    RepositoryList.prototype.generateDatasForFetch = function (query, body) {
        if (query === void 0) { query = {}; }
        if (body === void 0) { body = {}; }
        var finalQuery = (!query || query._limit) ? query : __assign(__assign({}, (query !== null && query !== void 0 ? query : {})), { _limit: 10 });
        console.log('fetch body', body);
        console.log('fetch this.props.data', this.props.data);
        var payload = (body === null || body === void 0 ? void 0 : body.length) > 0 ? {
            expressions: body,
            limitResponse: true
        } : this.props.data;
        console.log('fetch payload', payload);
        return { finalQuery: finalQuery, payload: payload };
    };
    RepositoryList.prototype.fetchResponse = function (finalQuery, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var parsedQuery, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        parsedQuery = router_1.Router.encodeQuery(finalQuery, true);
                        return [4, this.props.repository[this.props.method || 'get']("" + this.props.endpoint + parsedQuery, {
                                cancelToken: this.cancelToken.token,
                                data: payload
                            })];
                    case 1:
                        response = _a.sent();
                        return [2, response];
                }
            });
        });
    };
    RepositoryList.prototype.fetch = function (query, body) {
        if (query === void 0) { query = {}; }
        if (body === void 0) { body = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var _a, finalQuery, payload, response, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.generateDatasForFetch(query, body), finalQuery = _a.finalQuery, payload = _a.payload;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        if (this.cancelToken) {
                            this.cancelToken.cancel('CanceledByUser');
                            this.cancelToken = repository_1.Repository.createCancelToken();
                        }
                        this.setState({
                            loading: true
                        });
                        return [4, this.fetchResponse(finalQuery, payload)];
                    case 2:
                        response = _b.sent();
                        this.handleResponse(response);
                        return [3, 4];
                    case 3:
                        e_1 = _b.sent();
                        if (e_1.message === 'CanceledByUser') {
                            this.setState({
                                loading: false
                            });
                            return [2];
                        }
                        this.setState({
                            loading: false,
                            error: e_1
                        });
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    RepositoryList.prototype.handleResponse = function (response) {
        if (this.props.control) {
            this.props.control.setResponse(response);
        }
        this.setState({
            loading: false,
            response: response,
            items: this.extractItems(response),
            error: false,
            total: response.total
        });
        if (this.props.onChange) {
            this.props.onChange(this);
        }
    };
    RepositoryList.prototype.componentDidMount = function () {
        var _this = this;
        var _a;
        _super.prototype.componentDidMount.call(this);
        this.fetch((_a = this.props.control) === null || _a === void 0 ? void 0 : _a.data);
        if (this.props.control) {
            this.$subscriptions.push(this.props.control.query$.subscribe(function (query) { return _this.fetch(query); }));
        }
    };
    RepositoryList.prototype.reloadWithExpressions = function (expressions) {
        var _a;
        console.log('reloadWithExpressions expressions', expressions);
        this.fetch((_a = this.props.control) === null || _a === void 0 ? void 0 : _a.data, expressions);
    };
    RepositoryList.prototype.reload = function () {
        var _a, _b;
        console.log('reload', (_a = this.props.control) === null || _a === void 0 ? void 0 : _a.data, this.props.data);
        this.fetch((_b = this.props.control) === null || _b === void 0 ? void 0 : _b.data);
    };
    RepositoryList.prototype.silentRefresh = function (expression) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _b, finalQuery, payload, response;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        console.log('silentRefresh');
                        _b = this.generateDatasForFetch((_a = this.props.control) === null || _a === void 0 ? void 0 : _a.data), finalQuery = _b.finalQuery, payload = _b.payload;
                        return [4, this.fetchResponse(finalQuery, payload)];
                    case 1:
                        response = _c.sent();
                        this.setState(function (prevState) {
                            var newItems = _this.extractItems(response);
                            return __assign(__assign({}, prevState), { items: prevState.items.map(function (item, index) {
                                    if (expression(item)) {
                                        var newCalcItem = newItems.find(function (newItem) { return item._id === newItem._id; });
                                        return newCalcItem;
                                    }
                                    return item;
                                }) });
                        });
                        if (this.props.onChange) {
                            this.props.onChange(this);
                        }
                        return [2];
                }
            });
        });
    };
    RepositoryList.prototype.render = function () {
        return this.renderWrapper(this);
    };
    RepositoryList.prototype.renderWrapper = function (ctx) {
        if (this.props.renderWrapper) {
            return this.props.renderWrapper(ctx);
        }
        return React.createElement(Dimmer_1.default.Dimmable, { className: "ListWrapper" },
            this.renderError(this),
            this.renderItemsWrapper(this));
    };
    RepositoryList.prototype.renderItemsWrapper = function (ctx) {
        if (this.props.renderItemsWrapper) {
            return this.props.renderItemsWrapper(ctx);
        }
        return this.renderItems(ctx);
    };
    RepositoryList.prototype.renderItems = function (ctx) {
        var _this = this;
        var items = this.getItems();
        return [
            this.props.renderBeforeItems && this.props.renderBeforeItems(ctx),
            this.renderLoading(this),
            this.renderEmpty(items),
            items.map(function (item, index) { return _this.renderItemWrapper(ctx, item, index); }),
            this.props.renderAfterItems && this.props.renderAfterItems(ctx)
        ];
    };
    RepositoryList.prototype.renderEmpty = function (items) {
        if (items.length) {
            return null;
        }
        if (this.state.loading) {
            return null;
        }
        if (this.props.renderEmpty) {
            return this.props.renderEmpty(this);
        }
        return React.createElement("div", null, "Empty list");
    };
    RepositoryList.prototype.renderItemWrapper = function (ctx, item, index) {
        if (this.props.showItem && !(this.props.showItem && this.props.showItem(this, item, index))) {
            return;
        }
        return this.renderItem(ctx, item, index);
    };
    RepositoryList.prototype.renderItem = function (ctx, item, index) {
        if (this.props.renderItem) {
            return this.props.renderItem(ctx, item, index);
        }
        return React.createElement("div", { key: index }, JSON.stringify(item));
    };
    RepositoryList.prototype.renderError = function (ctx) {
        if (!this.props.showError || !(this.props.showError && this.props.showError(this))) {
            return;
        }
        if (!this.state.error) {
            return null;
        }
        if (this.props.hideErrorWhileLoading && this.state.error) {
            return null;
        }
        if (this.props.renderError) {
            return this.props.renderError(ctx);
        }
        return React.createElement("div", null, "Error");
    };
    RepositoryList.prototype.renderLoading = function (ctx) {
        if (!this.props.showLoading || !(this.props.showLoading && this.props.showLoading(this))) {
            return;
        }
        if (!this.state.loading) {
            return null;
        }
        if (this.props.hideLoadingWhileError && this.state.error) {
            return null;
        }
        if (this.props.renderLoading) {
            return this.props.renderLoading(ctx);
        }
        return React.createElement(semantic_ui_react_1.Loader, { active: true, key: Math.random() });
    };
    RepositoryList.prototype.getItems = function () {
        var _a;
        if (this.props.hideItemsWhileError && this.state.error) {
            return [];
        }
        if (this.props.hideItemsWhileLoading && this.state.loading) {
            return [];
        }
        return (_a = this.state.items) !== null && _a !== void 0 ? _a : [];
    };
    RepositoryList.prototype.extractItems = function (response) {
        var _a;
        if (this.props.extractItems) {
            return this.props.extractItems(response);
        }
        var i = (_a = response.items) !== null && _a !== void 0 ? _a : response;
        if (!Array.isArray(i)) {
            return [];
        }
        return i;
    };
    return RepositoryList;
}(responsive_component_1.ResponsiveComponent));
exports.RepositoryList = RepositoryList;
