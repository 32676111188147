"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomNewsletterDetailsScreen = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/uikit/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var semantic_ui_select_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.select.form-control-type");
var moment = require("moment");
var React = require("react");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../libs/session.storage");
var custom_newsletter_template_update_component_1 = require("../admin/components/template/custom-newsletter-template-update.component");
var custom_newsletter_template_content_component_1 = require("../admin/components/template/template-content/custom-newsletter-template-content.component");
var list_loader_1 = require("../common/components/loader/list-loader");
var handle_toasts_1 = require("../common/libs/handle.toasts");
var state_repository_1 = require("../common/libs/state-repository");
var preview_1 = require("../newsletter/components/preview");
var send_email_1 = require("./components/send-email");
var delete_custom_newsletter_modal_1 = require("./delete-custom-newsletter.modal");
var sensitive_data_1 = require("../common/libs/sensitive-data");
var observable_control_1 = require("@codebuild/uikit/libs/observable-control");
var CustomNewsletterDetailsScreen = (function (_super) {
    __extends(CustomNewsletterDetailsScreen, _super);
    function CustomNewsletterDetailsScreen() {
        var _a;
        var _this = _super.apply(this, arguments) || this;
        _this.customNewsletterTemplate = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'customNewsletterTemplate');
        _this.preview = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'preview');
        _this.copy = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'copy');
        _this.sendTestCustomEmailRepository = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'sendTestCustomEmaildTest');
        _this.customerSearch$ = new rxjs_1.Subject();
        _this.state = __assign(__assign({}, _this.state), { canItSend: false });
        _this.customerForm = new form_builder_1.FormBuilder({
            fields: {
                email: new field_1.Field({
                    name: 'email',
                    value: '',
                    placeholder: 'Válassz az érdeklődőid közül',
                    label: 'Érdeklődők',
                    optionsRepository: repository_1.Repository.use('default'),
                    optionsEndpoint: "/s/" + ((_a = session_storage_1.SessionStorage.getItem('SelectedShop')) === null || _a === void 0 ? void 0 : _a._id) + "/data/temporary/customer",
                    optionsMap: function (response) { return ((response === null || response === void 0 ? void 0 : response.items) || []).map(function (item) {
                        var _a;
                        return ({
                            text: sensitive_data_1.SensitiveData.customerEmail((_a = item === null || item === void 0 ? void 0 : item.data) === null || _a === void 0 ? void 0 : _a.email),
                            key: item.identifier,
                            value: item.identifier
                        });
                    }); },
                    loadOptionsAfterMount: false
                })
            }
        });
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                name: new field_1.Field({
                    name: 'name',
                    label: 'Név',
                    placeholder: 'Email neve',
                    value: '',
                    validators: [
                        new required_validator_1.RequiredValidator('Kötelező mező!')
                    ]
                }),
                description: new field_1.Field({
                    name: 'description',
                    label: 'Leírás',
                    placeholder: 'Leírás',
                    value: '',
                    validators: []
                }),
                subject: new field_1.Field({
                    name: 'subject',
                    label: 'Tárgy mező',
                    placeholder: 'Ez a levélnek a tárgya',
                    value: '',
                    validators: [
                        new required_validator_1.RequiredValidator('Kötelező mező!')
                    ]
                }),
            }
        });
        return _this;
    }
    CustomNewsletterDetailsScreen.prototype.updatePreview = function () {
        return __awaiter(this, void 0, void 0, function () {
            var shop;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        shop = session_storage_1.SessionStorage.getItem('SelectedShop');
                        return [4, this.preview.get("/s/" + shop._id + "/custom-newsletter-template/" + this.props.route.params.id + "/preview", { params: this.customerForm.toJSON() })];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    CustomNewsletterDetailsScreen.prototype.copyNewsletter = function () {
        return __awaiter(this, void 0, void 0, function () {
            var shop, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        shop = session_storage_1.SessionStorage.getItem('SelectedShop');
                        return [4, this.preview.put("/s/" + shop._id + "/custom-newsletter-template/" + this.props.route.params.id + "/copy")];
                    case 1:
                        _a.sent();
                        return [4, router_provider_1.RouterProvider.goTo('/custom-newsletters')];
                    case 2:
                        _a.sent();
                        handle_toasts_1.HandleToasts.error('Sikeres másolás');
                        return [3, 4];
                    case 3:
                        e_1 = _a.sent();
                        handle_toasts_1.HandleToasts.error('Sajnos nem sikerült a művelet végrehajtása');
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    CustomNewsletterDetailsScreen.prototype.getEndpoint = function () {
        var _a;
        return "/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/custom-newsletter-template";
    };
    CustomNewsletterDetailsScreen.prototype.isEditable = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (!((_a = this.state.customNewsletterTemplate) === null || _a === void 0 ? void 0 : _a.response)) {
            return false;
        }
        if ((_b = this.state.customNewsletterTemplate) === null || _b === void 0 ? void 0 : _b.loading) {
            return false;
        }
        if (((_d = (_c = this.state.customNewsletterTemplate) === null || _c === void 0 ? void 0 : _c.response) === null || _d === void 0 ? void 0 : _d.status) === 'sent') {
            return false;
        }
        if (((_f = (_e = this.state.customNewsletterTemplate) === null || _e === void 0 ? void 0 : _e.response) === null || _f === void 0 ? void 0 : _f.status) === 'inprogress') {
            return false;
        }
        if (((_h = (_g = this.state.customNewsletterTemplate) === null || _g === void 0 ? void 0 : _g.response) === null || _h === void 0 ? void 0 : _h.status) === 'scheduled') {
            return false;
        }
        return true;
    };
    CustomNewsletterDetailsScreen.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.$subscriptions.push(this.form.$value.subscribe(function (a) { return _this.contentUpdateForm.form.updateFields(a); }), this.form.$submitSuccess
                            .subscribe(function () { return _this.updateContent(); }), this.customerForm.$value
                            .pipe(operators_1.debounceTime(250))
                            .subscribe(function (v) { return _this.updatePreview(); }), this.customerSearch$
                            .pipe(operators_1.debounceTime(250))
                            .subscribe(function (query) { return _this.customerForm.fields.email.loadOptions({ query: query }); }));
                        return [4, this.fetchData()];
                    case 1:
                        _a.sent();
                        return [4, this.customerForm.fields.email.loadOptions()];
                    case 2:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    CustomNewsletterDetailsScreen.prototype.updateCanItSend = function () {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function () {
            var f1Errors, f2Errors, f3Errors, f1ErrorArray, f2ErrorArray, f3ErrorArray, errorsArray;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0: return [4, ((_a = this.form) === null || _a === void 0 ? void 0 : _a.getErrors())];
                    case 1:
                        f1Errors = _f.sent();
                        return [4, ((_c = (_b = this.contentUpdateForm) === null || _b === void 0 ? void 0 : _b.form) === null || _c === void 0 ? void 0 : _c.getErrors())];
                    case 2:
                        f2Errors = _f.sent();
                        return [4, ((_e = (_d = this.contentUpdateForm) === null || _d === void 0 ? void 0 : _d.defaultVariablesForm) === null || _e === void 0 ? void 0 : _e.getErrors())];
                    case 3:
                        f3Errors = _f.sent();
                        f1ErrorArray = Object.keys(f1Errors).map(function (key) { return f1Errors[key]; }).flat();
                        f2ErrorArray = Object.keys(f2Errors).map(function (key) { return f2Errors[key]; }).flat();
                        f3ErrorArray = Object.keys(f3Errors).map(function (key) { return f3Errors[key]; }).flat();
                        errorsArray = __spreadArrays(f1ErrorArray, f2ErrorArray, f3ErrorArray);
                        return [4, this.setState({ canItSend: !(errorsArray === null || errorsArray === void 0 ? void 0 : errorsArray.length) })];
                    case 4:
                        _f.sent();
                        return [2];
                }
            });
        });
    };
    CustomNewsletterDetailsScreen.prototype.fetchData = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var result, e_2;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.customNewsletterTemplate.get("/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/custom-newsletter-template/" + this.props.route.params.id)];
                    case 1:
                        result = _b.sent();
                        return [4, this.form.fields.name.setValue(result.name)];
                    case 2:
                        _b.sent();
                        return [4, this.form.fields.description.setValue(result.description)];
                    case 3:
                        _b.sent();
                        return [4, this.form.fields.subject.setValue(result.subject)];
                    case 4:
                        _b.sent();
                        _b.label = 5;
                    case 5:
                        _b.trys.push([5, 7, , 8]);
                        return [4, this.updatePreview()];
                    case 6:
                        _b.sent();
                        return [3, 8];
                    case 7:
                        e_2 = _b.sent();
                        return [3, 8];
                    case 8:
                        setTimeout(function () { return _this.updateCanItSend(); }, 500);
                        return [2];
                }
            });
        });
    };
    CustomNewsletterDetailsScreen.prototype.updateFilter = function (filter) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.customNewsletterTemplate.putWithPureAxios("/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/custom-newsletter-template/" + this.props.route.params.id, {
                            data: {
                                filterId: filter._id,
                                filter: filter.payload,
                            }
                        })];
                    case 1:
                        _b.sent();
                        setTimeout(function () { return _this.updateCanItSend(); }, 500);
                        return [2];
                }
            });
        });
    };
    CustomNewsletterDetailsScreen.prototype.updateContent = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return __awaiter(this, void 0, void 0, function () {
            var f2Errors, f1Errors, f3Errors, previewEmailAddress;
            return __generator(this, function (_l) {
                switch (_l.label) {
                    case 0: return [4, ((_b = (_a = this.contentUpdateForm) === null || _a === void 0 ? void 0 : _a.form) === null || _b === void 0 ? void 0 : _b.submit())];
                    case 1:
                        _l.sent();
                        return [4, ((_d = (_c = this.contentUpdateForm) === null || _c === void 0 ? void 0 : _c.defaultVariablesForm) === null || _d === void 0 ? void 0 : _d.submit())];
                    case 2:
                        _l.sent();
                        return [4, ((_f = (_e = this.contentUpdateForm) === null || _e === void 0 ? void 0 : _e.form) === null || _f === void 0 ? void 0 : _f.validate())];
                    case 3:
                        f2Errors = _l.sent();
                        return [4, ((_g = this.form) === null || _g === void 0 ? void 0 : _g.validate())];
                    case 4:
                        f1Errors = _l.sent();
                        return [4, ((_j = (_h = this.contentUpdateForm) === null || _h === void 0 ? void 0 : _h.defaultVariablesForm) === null || _j === void 0 ? void 0 : _j.validate())];
                    case 5:
                        f3Errors = _l.sent();
                        if ([f1Errors, f2Errors, f3Errors].includes(false)) {
                            handle_toasts_1.HandleToasts.error('Minden mező kitöltése kötelező!', 'Minden mező kitöltése kötelező!');
                            return [2];
                        }
                        return [4, this.customNewsletterTemplate.put("/s/" + ((_k = this.getCurrentShop()) === null || _k === void 0 ? void 0 : _k._id) + "/custom-newsletter-template/" + this.props.route.params.id, {
                                data: {
                                    subject: this.form.fields.subject.getValue(),
                                }
                            })];
                    case 6:
                        _l.sent();
                        return [4, this.fetchData()];
                    case 7:
                        _l.sent();
                        previewEmailAddress = this.customerForm.fields.email.getValue();
                        if (previewEmailAddress) {
                            this.customerSearch$.next(previewEmailAddress);
                        }
                        return [2];
                }
            });
        });
    };
    CustomNewsletterDetailsScreen.prototype.sendTestCustomEmail = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var cid, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        cid = this.customerForm.fields.email.getValue();
                        console.log("Sending test custom email to " + cid);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4, this.sendTestCustomEmailRepository.post("/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/custom-newsletter-template/" + this.props.route.params.id + "/schedule-test-custom-email", {
                                data: {
                                    cid: cid
                                }
                            })];
                    case 2:
                        _b.sent();
                        handle_toasts_1.HandleToasts.success('Sikeres teszt email küldés!');
                        return [3, 4];
                    case 3:
                        error_1 = _b.sent();
                        console.log('error', error_1);
                        handle_toasts_1.HandleToasts.error(error_1, 'Sajnos nem sikerült kiküldeni a teszt levelet!');
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    CustomNewsletterDetailsScreen.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return React.createElement("div", { className: "WebshopDataScreen CustomNewsletterScreen" },
            React.createElement(semantic_ui_react_1.Container, { fluid: true },
                React.createElement(semantic_ui_react_1.Grid, { className: "WebshopDataScreenHeader" },
                    React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                        React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                            React.createElement(semantic_ui_react_1.Header, { as: 'h2', content: (_b = (_a = this.state.customNewsletterTemplate) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.name, subheader: (_d = (_c = this.state.customNewsletterTemplate) === null || _c === void 0 ? void 0 : _c.response) === null || _d === void 0 ? void 0 : _d.description })),
                        React.createElement(semantic_ui_react_1.Grid.Column, { className: "display-flex", width: 10 },
                            React.createElement(semantic_ui_react_1.Button, { basic: true, onClick: function () { return _this.copyNewsletter(); }, floated: "right", className: 'mr-4' },
                                "Lev\u00E9l m\u00E1sol\u00E1sa",
                                React.createElement(semantic_ui_react_1.Icon, { name: "copy", className: 'ml-2' })),
                            React.createElement(semantic_ui_react_1.Button, { disabled: !this.isEditable(), onClick: function () { return _this.updateContent(); }, floated: "right", className: 'mr-4' },
                                "Lev\u00E9l ment\u00E9se",
                                React.createElement(semantic_ui_react_1.Icon, { name: "save", className: 'ml-2' })),
                            React.createElement(send_email_1.SendEmail, { disabled: !this.isEditable() || !this.state.canItSend, _id: (_f = (_e = this.state.customNewsletterTemplate) === null || _e === void 0 ? void 0 : _e.response) === null || _f === void 0 ? void 0 : _f._id, handleTargetSelect: function (value) { return _this.updateFilter(value); }, selectedTarget: (_h = (_g = this.state.customNewsletterTemplate) === null || _g === void 0 ? void 0 : _g.response) === null || _h === void 0 ? void 0 : _h.filterId })))),
                this.renderItems(),
                React.createElement(delete_custom_newsletter_modal_1.DeleteCustomNewsletterModal, { _id: (_k = (_j = this.state.customNewsletterTemplate) === null || _j === void 0 ? void 0 : _j.response) === null || _k === void 0 ? void 0 : _k._id })));
    };
    CustomNewsletterDetailsScreen.prototype.renderLeft = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        return React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1 },
            React.createElement(semantic_ui_react_1.Grid.Column, null,
                React.createElement(semantic_ui_react_1.Segment, { vertical: true },
                    React.createElement(semantic_ui_react_1.Header, { as: "h3" }, "Lev\u00E9l t\u00E1rgya"),
                    React.createElement("p", null,
                        "\u00CDrd \u00E1t nyugodtan a javasolt t\u00E1rgy mez\u0151t hogyha neked nem tetszik. Eg\u00E9sz nyugodtan haszn\u00E1lj emojit a t\u00E1rgymez\u0151ben. Emojik list\u00E1j\u00E1t itt \u00E9red el: ",
                        React.createElement("a", { href: "http://unicode.org/emoji/charts/full-emoji-list.html" }, "http://unicode.org/emoji/charts/full-emoji-list.html")),
                    React.createElement(semantic_ui_react_1.Form, { onSubmit: function () { return _this.form.submit(); } },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.subject, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, disabled: !this.isEditable() })))),
                this.isEditable() && React.createElement(semantic_ui_react_1.Segment, { vertical: true },
                    React.createElement(semantic_ui_react_1.Header, { as: "h3" }, "Sablon szerkeszt\u00E9se"),
                    React.createElement("p", null, "A sablon szerkeszt\u00E9se gomb megnyom\u00E1sa ut\u00E1n egy olyan ter\u00FCletre ker\u00FClsz ahol kedved szerint b\u00E1rmilyen sablont \u00F6ssze\u00E1ll\u00EDthatsz."),
                    React.createElement(custom_newsletter_template_content_component_1.CustomNewsletterTemplateContentComponent, { _id: (_b = (_a = this.state.customNewsletterTemplate) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b._id, onSave: function () { return _this.fetchData(); } })),
                this.isEditable() && React.createElement(semantic_ui_react_1.Segment, { vertical: true },
                    React.createElement(semantic_ui_react_1.Header, { as: "h3" }, "Tartalom m\u00F3dos\u00EDt\u00E1sa"),
                    React.createElement("p", null, "Az \u00F6sszerakott sablonod tartalm\u00E1t a lenti mez\u0151k seg\u00EDts\u00E9g\u00E9vel val\u00F3s sz\u00F6veggel t\u00F6ltheted fel."),
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(custom_newsletter_template_update_component_1.CustomNewsletterTemplateUpdateComponent, { canItUpdateCallback: function () { return _this.form.validate(); }, ref: function (ref) { return (_this.contentUpdateForm = ref); }, disableModal: true, _id: (_d = (_c = this.state.customNewsletterTemplate) === null || _c === void 0 ? void 0 : _c.response) === null || _d === void 0 ? void 0 : _d._id, className: "noWrap mr-4", content: trans_1.trans('custom-newsletter.screen.create-button.title') }))),
                !this.isEditable() && React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Header, { as: "h3" }, "Kik\u00FCld\u00E9s eredm\u00E9nye"),
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement("p", null,
                            "\u00DCtemezve: ", (_g = (_f = (_e = this.state.customNewsletterTemplate) === null || _e === void 0 ? void 0 : _e.response) === null || _f === void 0 ? void 0 : _f.artifact) === null || _g === void 0 ? void 0 :
                            _g.successCount),
                        React.createElement("p", null,
                            "Sikertelen\u00FCl \u00FCtemezve: ", (_k = (_j = (_h = this.state.customNewsletterTemplate) === null || _h === void 0 ? void 0 : _h.response) === null || _j === void 0 ? void 0 : _j.artifact) === null || _k === void 0 ? void 0 :
                            _k.failedCount),
                        React.createElement("p", null,
                            "\u00DCtemez\u00E9s d\u00E1tuma: ",
                            ((_m = (_l = this.state.customNewsletterTemplate) === null || _l === void 0 ? void 0 : _l.response) === null || _m === void 0 ? void 0 : _m.originalScheduleDate) ? moment((_p = (_o = this.state.customNewsletterTemplate) === null || _o === void 0 ? void 0 : _o.response) === null || _p === void 0 ? void 0 : _p.originalScheduleDate).format() : '-')))));
    };
    CustomNewsletterDetailsScreen.prototype.renderRight = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1 },
            React.createElement(semantic_ui_react_1.Grid.Column, null,
                React.createElement(semantic_ui_react_1.Segment, { vertical: true },
                    React.createElement(semantic_ui_react_1.Header, { as: "h3" }, "\u00C9rdekl\u0151d\u0151 v\u00E1laszt\u00E1sa el\u0151n\u00E9zethez"),
                    React.createElement("p", null, "V\u00E1lassz egy felhaszn\u00E1l\u00F3t a fenti list\u00E1b\u00F3l akivel megn\u00E9zheted ennek a lev\u00E9lnek az el\u0151n\u00E9zet\u00E9t. A list\u00E1ban olyan felhaszn\u00E1l\u00F3kat is tal\u00E1lsz akire ennek a lev\u00E9lnek a szab\u00E1lyai nem vonatkoznak."),
                    React.createElement(semantic_ui_react_1.Form, { className: "display-flex pb-4 w-100" },
                        React.createElement(form_control_1.FormControl, { field: this.customerForm.fields.email, type: semantic_ui_select_form_control_type_1.SemanticUiSelectFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { className: "w-100", control: semantic_ui_react_1.Dropdown, clearable: true, selection: true, search: true, fluid: true, disabled: false, onSearchChange: function (event) { return _this.customerSearch$.next(event.target.value); } }))),
                    React.createElement(preview_1.Preview, { loading: (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.preview) === null || _b === void 0 ? void 0 : _b.loading, error: (_e = (_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.preview) === null || _d === void 0 ? void 0 : _d.error) === null || _e === void 0 ? void 0 : _e.message, subject: (_g = (_f = this.state.preview) === null || _f === void 0 ? void 0 : _f.response) === null || _g === void 0 ? void 0 : _g.subject, html: (_j = (_h = this.state.preview) === null || _h === void 0 ? void 0 : _h.response) === null || _j === void 0 ? void 0 : _j.content }),
                    React.createElement(observable_control_1.ObservableControl, { "$observable": this.customerForm.fields.email.$value, transform: function (value) {
                            var previewEmailAddress = _this.customerForm.fields.email.getValue();
                            var isDisabledByPreviewEmail = previewEmailAddress === null || previewEmailAddress === undefined || previewEmailAddress === '';
                            if (!isDisabledByPreviewEmail) {
                                return ({ disabled: false });
                            }
                            return ({ disabled: !value });
                        } },
                        React.createElement(semantic_ui_react_1.Button, { className: "send-button", type: "button", icon: "envelope", loading: (_k = this.state.sendTest) === null || _k === void 0 ? void 0 : _k.loading, content: "Teszt egyedi email k\u00FCld\u00E9se", floated: "left", onClick: function () { return _this.sendTestCustomEmail(); }, style: {
                                marginTop: '8px'
                            } })))));
    };
    CustomNewsletterDetailsScreen.prototype.renderItems = function () {
        var _a;
        if ((_a = this.state.customNewsletterTemplate) === null || _a === void 0 ? void 0 : _a.loading) {
            return React.createElement(semantic_ui_react_1.Grid, { columns: 1 },
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Grid, { centered: true },
                        React.createElement(semantic_ui_react_1.Grid.Column, null,
                            React.createElement(semantic_ui_react_1.Grid.Column, null,
                                React.createElement(semantic_ui_react_1.Header, { as: "h3" }, "\u00C1ltal\u00E1nos be\u00E1ll\u00EDt\u00E1sok"),
                                React.createElement(list_loader_1.ListLoader, null))))));
        }
        return React.createElement(semantic_ui_react_1.Segment, { className: 'CustomNewsletterDetails' },
            React.createElement(semantic_ui_react_1.Grid, { columns: 2 },
                React.createElement(semantic_ui_react_1.Grid.Column, null, this.renderRight()),
                React.createElement(semantic_ui_react_1.Grid.Column, null, this.renderLeft())));
    };
    CustomNewsletterDetailsScreen.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    return CustomNewsletterDetailsScreen;
}(abstract_component_1.AbstractComponent));
exports.CustomNewsletterDetailsScreen = CustomNewsletterDetailsScreen;
