"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SendEmail = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var React = require("react");
var DatePicker = require("react-datepicker");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../libs/session.storage");
var state_repository_1 = require("../../common/libs/state-repository");
var filter_expression_count_1 = require("./filter-expression-count");
var saved_filters_bar_1 = require("../../common/components/customer-filter/saved-filters-bar");
var react_datepicker_form_control_type_1 = require("../../../libs/form-control-types/react-datepicker.form-control-type");
var hu_1 = require("date-fns/locale/hu");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var handle_toasts_1 = require("../../common/libs/handle.toasts");
var moment = require("moment");
DatePicker.registerLocale('hu', hu_1.default);
var SendEmail = (function (_super) {
    __extends(SendEmail, _super);
    function SendEmail() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'repository');
        _this.state = {
            datePickerError: '',
            isModalOpen: false,
            isConfirmationModalOpen: false,
            showDatePicker: false,
            loading: false
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                optimized: new field_1.Field({
                    name: 'timing-optimized',
                    label: 'Optimalizált',
                    value: true,
                }),
                timed: new field_1.Field({
                    name: 'timing-timed',
                    label: 'Időzítve',
                    value: false,
                }),
                now: new field_1.Field({
                    name: 'timing-now',
                    label: 'Azonnal',
                    value: false,
                }),
                date: new field_1.Field({
                    name: 'date',
                    label: 'Kiküldés ideje',
                    placeholder: 'Kiküldés ideje',
                    value: moment().toDate(),
                })
            }
        });
        _this.testForm = new form_builder_1.FormBuilder({
            fields: {
                testEmail: new field_1.Field({
                    name: 'testEmail',
                    label: 'Email',
                    placeholder: 'Email',
                    value: ''
                })
            }
        });
        return _this;
    }
    SendEmail.prototype.componentDidMount = function () {
        var _this = this;
        console.log("DATE:", new Date(new Date().getTime() + 30000));
        this.$subscriptions.push(this.form.$submitSuccess.subscribe(function () { return _this.sending(); }));
    };
    SendEmail.prototype.sending = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var data;
            var _this = this;
            return __generator(this, function (_b) {
                if (this.form.fields.date.getValue() < moment().add(10, 'm').toDate()) {
                    this.setState({ isConfirmationModalOpen: false, datePickerError: 'Az e-mail küldés időpontja nem lehet korábbi, mint a jelenlegi időpont + 15 perc!' });
                    return [2];
                }
                this.setState({ loading: true });
                data = {
                    optimized: this.form.fields.optimized.getValue(),
                    date: this.form.fields.date.getValue(),
                };
                try {
                    this.repository.post("/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/custom-newsletter-template/" + this.props._id + "/schedule", {
                        data: data
                    });
                    setTimeout(function () {
                        router_provider_1.RouterProvider.goTo("/custom-newsletters/success/" + _this.props._id);
                        handle_toasts_1.HandleToasts.success('Sikeres kiküldés');
                    }, 5000);
                }
                catch (e) {
                    handle_toasts_1.HandleToasts.error('Sajnos nem sikerült elindítani a levélküldést!');
                    this.setState({ loading: false });
                }
                return [2];
            });
        });
    };
    SendEmail.prototype.handleTimingChange = function (field) {
        this.form.fields.optimized.setValue(false);
        this.form.fields.timed.setValue(false);
        this.form.fields.now.setValue(false);
        this.form.fields[field].setValue(true);
        if (field === 'timed') {
            return this.setState({ showDatePicker: true });
        }
        return this.setState({ showDatePicker: false });
    };
    SendEmail.prototype.handleOptimizedChange = function () {
        this.form.fields.optimized.setValue(!this.form.fields.optimized.getValue());
        return this.setState({ showDatePicker: false });
    };
    SendEmail.prototype.render = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Modal, { open: this.state.isModalOpen, trigger: this.renderTrigger(), content: this.renderContent(), onOpen: function () { return _this.setState({ isModalOpen: true }); }, onClose: function () { return _this.setState({ isModalOpen: false }); }, className: 'CustomNewsletterSendEmailModal' });
    };
    SendEmail.prototype.renderTrigger = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Button, { color: "green", floated: "right", disabled: this.props.disabled, onClick: function () { return _this.form.fields.date.setValue(moment().add(15, 'm').toDate()); } },
            "H\u00EDrlev\u00E9l kik\u00FCld\u00E9se",
            React.createElement(semantic_ui_react_1.Icon, { name: "paper plane", className: 'ml-2' }));
    };
    SendEmail.prototype.renderContent = function () {
        var _this = this;
        var _a, _b, _c, _d;
        var filterPassedTime = function (time) {
            var currentDate = moment().add(30, 'm').toDate();
            var selectedDate = new Date(time);
            return currentDate.getTime() < selectedDate.getTime();
        };
        var now = moment().toDate();
        return React.createElement(semantic_ui_react_1.Segment, null,
            React.createElement("h2", null, "H\u00EDrlev\u00E9l kik\u00FCld\u00E9se"),
            React.createElement(semantic_ui_react_1.Segment, { vertical: true },
                React.createElement(semantic_ui_react_1.Header, { as: "h4" },
                    "V\u00E1laszd ki kiknek k\u00FCld\u00F6d\u00A0",
                    React.createElement(filter_expression_count_1.FilterExpressionCount, { filter: (_b = (_a = this.state.customNewsletterTemplate) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.filter })),
                React.createElement("p", null, "Itt kiv\u00E1laszthatod az \u00E9rdekl\u0151d\u0151k oldalon is el\u00E9rhet\u0151 alap\u00E9rtelmezett sz\u0171r\u0151k b\u00E1rmelyik\u00E9t vagy az \u00E9rdekl\u0151d\u0151k oldalal\u00E1n ak\u00E1r l\u00E9tre is hozhatsz saj\u00E1t sz\u0171r\u00E9st."),
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(saved_filters_bar_1.SavedFiltersBar, { dark: true, regular: true, onSelect: function (value) { return _this.props.handleTargetSelect(value); }, selected: this.props.selectedTarget, buttonSize: 'small' }))),
            React.createElement(semantic_ui_react_1.Segment, { vertical: true },
                React.createElement(semantic_ui_react_1.Header, { as: "h4" },
                    "Mikor szeretn\u00E9d kik\u00FCldeni?\u00A0",
                    React.createElement(filter_expression_count_1.FilterExpressionCount, { filter: (_d = (_c = this.state.customNewsletterTemplate) === null || _c === void 0 ? void 0 : _c.response) === null || _d === void 0 ? void 0 : _d.filter })),
                React.createElement("p", null,
                    "Levelek kik\u00FCld\u00E9se t\u00F6rt\u00E9nhet az al\u00E1bb felsorolt m\u00F3dokban. Amennyiben az Optimaliz\u00E1lt id\u0151ben t\u00F6rt\u00E9n\u0151 kik\u00FCld\u00E9st v\u00E1lszatod, \u00FAgy a kiv\u00E1lasztott list\u00E1n mindenkinek akkor k\u00FCldi ki a rendszer amikor a legoptiml\u00E1isabb, teh\u00E1t a legt\u00F6bb es\u00E9ly van r\u00E1 hogy megnyitja. A m\u00E1r \u00FCtemezett h\u00EDrlevelek visszavon\u00E1s\u00E1ra a tervezett ",
                    React.createElement("strong", null, "k\u00FCld\u00E9si id\u0151pont el\u0151tti 5. percig"),
                    " van lehet\u0151s\u00E9g"),
                React.createElement(semantic_ui_react_1.Grid.Column, null,
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.date, type: react_datepicker_form_control_type_1.ReactDatepickerFormControlType },
                                React.createElement(DatePicker.default, { locale: 'hu', showTimeSelect: true, timeFormat: 'HH:mm', dateFormat: 'yyyy.MM.dd. HH:mm', filterTime: filterPassedTime, minDate: moment().toDate() })),
                            !!this.state.datePickerError && React.createElement("span", { style: { color: 'red', marginTop: -10, marginBottom: 10 } }, this.state.datePickerError)),
                        React.createElement(semantic_ui_react_1.Button, { className: "mb-2 mr-2", basic: !this.form.fields.optimized.getValue(), icon: false, size: 'small', onClick: function () { return _this.handleOptimizedChange(); } },
                            this.form.fields.optimized.getValue() && React.createElement(semantic_ui_react_1.Icon, { name: "check" }),
                            this.form.fields.optimized.getValue() && React.createElement("span", null, "\u00A0\u00A0"),
                            "Optimaliz\u00E1lt"),
                        React.createElement(semantic_ui_react_1.Grid.Column, { className: 'display-flex justify-content-end' },
                            React.createElement(semantic_ui_react_1.Button, { basic: true, color: "grey", onClick: function () { return _this.setState({ isModalOpen: false }); } }, "M\u00E9gsem"),
                            this.renderSendEmailConfirmationModal())))));
    };
    SendEmail.prototype.renderSendEmailConfirmationModal = function () {
        var _this = this;
        return React.createElement(semantic_ui_react_1.Modal, { open: this.state.isConfirmationModalOpen, trigger: this.renderConfirmationTrigger(), content: this.renderConfirmationModal(), onOpen: function () {
                if (_this.form.fields.date.getValue() > moment().add(15, 'm').toDate()) {
                    _this.setState({ isConfirmationModalOpen: true });
                }
                else {
                    _this.setState({ datePickerError: 'Az e-mail küldés időpontja nem lehet korábbi, mint a jelenlegi időpont + 15 perc!' });
                }
            }, onClose: function () { return _this.setState({ isConfirmationModalOpen: false }); }, className: 'CustomNewsletterSendEmailModal' });
    };
    SendEmail.prototype.renderConfirmationModal = function () {
        var _this = this;
        var _a, _b;
        return React.createElement(semantic_ui_react_1.Segment, null,
            React.createElement(semantic_ui_react_1.Form, { onSubmit: function () { return _this.form.submit(); } },
                React.createElement(semantic_ui_react_1.Grid.Column, { className: 'display-flex flex-column' },
                    React.createElement("h3", null, "Biztosan elind\u00EDtod a lev\u00E9lk\u00FCld\u00E9st?"),
                    React.createElement("span", null, "Ind\u00EDt\u00E1s ut\u00E1n a folyamatot m\u00E1r nem lehet le\u00E1ll\u00EDtani")),
                React.createElement(semantic_ui_react_1.Grid.Column, { className: 'display-flex justify-content-end' },
                    React.createElement(semantic_ui_react_1.Button, { basic: true, color: "grey", onClick: function () { return _this.setState({ isConfirmationModalOpen: false }); } }, "M\u00E9gsem"),
                    React.createElement(semantic_ui_react_1.Button, { type: "submit", loading: (_a = this.state) === null || _a === void 0 ? void 0 : _a.loading, disabled: (_b = this.state) === null || _b === void 0 ? void 0 : _b.loading, color: "green" }, "Meger\u0151s\u00EDt\u00E9s"))));
    };
    SendEmail.prototype.renderConfirmationTrigger = function () {
        var _a;
        return React.createElement(semantic_ui_react_1.Button, { disabled: (_a = this.state) === null || _a === void 0 ? void 0 : _a.loading, color: "green" }, "Lev\u00E9lk\u00FCld\u00E9s ind\u00EDt\u00E1sa");
    };
    SendEmail.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    return SendEmail;
}(abstract_component_1.AbstractComponent));
exports.SendEmail = SendEmail;
