"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomNewsletterRow = void 0;
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var moment = require("moment");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../libs/session.storage");
var number_parser_1 = require("../../common/libs/number-parser");
var state_repository_1 = require("../../common/libs/state-repository");
require("./custom-newsletter-row.scss");
var CustomNewsletterRow = (function (_super) {
    __extends(CustomNewsletterRow, _super);
    function CustomNewsletterRow() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.performance = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'performance');
        _this.repository = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'repository');
        return _this;
    }
    CustomNewsletterRow.prototype.componentDidMount = function () {
        var _a;
        _super.prototype.componentDidMount.call(this);
        this.performance.get("/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/email-artifact/performance-2/" + this.props.item._id, {
            params: {
                from: moment().add(-10, 'years').toDate(),
                to: moment().toDate()
            }
        });
    };
    CustomNewsletterRow.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    CustomNewsletterRow.prototype.cancelEmail = function (id) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.repository.put("/s/" + ((_a = this.getCurrentShop()) === null || _a === void 0 ? void 0 : _a._id) + "/custom-newsletter-template/" + id + "/cancel")];
                    case 1:
                        _b.sent();
                        this.props.refresh();
                        return [2];
                }
            });
        });
    };
    CustomNewsletterRow.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
        return React.createElement(semantic_ui_react_1.Table.Row, { key: this.props.item._id, verticalAlign: "middle", onClick: function (s, e) { return s.target.className === "ui button" ? "" : _this.props.onClick(_this.props.item); } },
            React.createElement(semantic_ui_react_1.Table.Cell, null,
                React.createElement("p", null, this.props.item.name),
                React.createElement("span", null, this.props.item.description)),
            this.props.item.status !== 'sent' && (React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: 3 },
                (!this.props.item.customersProcessed || !this.props.item.customerCount) && (React.createElement("div", { style: { minWidth: '200px', height: 25, backgroundColor: '#E7E7EB', borderRadius: '5px', overflow: 'hidden', textAlign: 'center', position: 'relative' } },
                    React.createElement("div", { style: { width: (0) + '%', height: '100%', backgroundColor: 'cornflowerblue', position: 'absolute' } }),
                    React.createElement("span", { style: { position: 'relative' } },
                        React.createElement("strong", null, "0%")))),
                (this.props.item.customersProcessed && this.props.item.customerCount) && (React.createElement("div", { style: { minWidth: '200px', height: 25, backgroundColor: '#E7E7EB', borderRadius: '5px', overflow: 'hidden', textAlign: 'center', position: 'relative' } },
                    React.createElement("div", { style: { width: (this.props.item.customersProcessed / this.props.item.customerCount * 100) + '%', height: '100%', backgroundColor: 'cornflowerblue', position: 'absolute' } }),
                    React.createElement("span", { style: { position: 'relative' } },
                        React.createElement("strong", null, Math.floor(this.props.item.customersProcessed / this.props.item.customerCount * 100) + '%')))))),
            this.props.item.status === 'sent' && (React.createElement(React.Fragment, null,
                React.createElement(semantic_ui_react_1.Table.Cell, null,
                    React.createElement("div", { className: "w-100 display-flex justify-content-center align-items-center flex-column" },
                        ((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.performance) === null || _b === void 0 ? void 0 : _b.loading) && React.createElement("h4", { className: "pb-0 mb-0" }, "-"),
                        !((_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.performance) === null || _d === void 0 ? void 0 : _d.loading) &&
                            React.createElement("h4", { className: "pb-0 mb-0" }, number_parser_1.splitToNumberString((_h = (_g = (_f = (_e = this.state) === null || _e === void 0 ? void 0 : _e.performance) === null || _f === void 0 ? void 0 : _f.response) === null || _g === void 0 ? void 0 : _g.stats) === null || _h === void 0 ? void 0 : _h.sent)),
                        React.createElement("span", null, "Kik\u00FCldve"))),
                React.createElement(semantic_ui_react_1.Table.Cell, null,
                    React.createElement("div", { className: "w-100 display-flex justify-content-center align-items-center flex-column" },
                        ((_k = (_j = this.state) === null || _j === void 0 ? void 0 : _j.performance) === null || _k === void 0 ? void 0 : _k.loading) && React.createElement("h4", { className: "pb-0 mb-0" }, "-"),
                        !((_m = (_l = this.state) === null || _l === void 0 ? void 0 : _l.performance) === null || _m === void 0 ? void 0 : _m.loading) &&
                            React.createElement("h4", { className: "pb-0 mb-0" }, number_parser_1.splitToNumberString((_r = (_q = (_p = (_o = this.state) === null || _o === void 0 ? void 0 : _o.performance) === null || _p === void 0 ? void 0 : _p.response) === null || _q === void 0 ? void 0 : _q.stats) === null || _r === void 0 ? void 0 : _r.click)),
                        React.createElement("span", null, "Kattintott"))),
                React.createElement(semantic_ui_react_1.Table.Cell, null,
                    React.createElement("div", { className: "w-100 display-flex justify-content-center align-items-center flex-column" },
                        ((_t = (_s = this.state) === null || _s === void 0 ? void 0 : _s.performance) === null || _t === void 0 ? void 0 : _t.loading) && React.createElement("h4", { className: "pb-0 mb-0" }, "-"),
                        !((_v = (_u = this.state) === null || _u === void 0 ? void 0 : _u.performance) === null || _v === void 0 ? void 0 : _v.loading) &&
                            React.createElement("h4", { className: "pb-0 mb-0" }, number_parser_1.splitToNumberString((_z = (_y = (_x = (_w = this.state) === null || _w === void 0 ? void 0 : _w.performance) === null || _x === void 0 ? void 0 : _x.response) === null || _y === void 0 ? void 0 : _y.stats) === null || _z === void 0 ? void 0 : _z.opened)),
                        React.createElement("span", null, "Megnyitott"))))),
            React.createElement(semantic_ui_react_1.Table.Cell, null, this.props.item.originalScheduleDate && moment(this.props.item.originalScheduleDate).format('YYYY-MM-DD HH:mm') || '-'),
            React.createElement(semantic_ui_react_1.Table.Cell, null, moment(this.props.item.createdAt).format('YYYY-MM-DD HH:mm')),
            React.createElement(semantic_ui_react_1.Table.Cell, null, this.props.item.contentUpdatedAt ? moment(this.props.item.contentUpdatedAt).format('YYYY-MM-DD HH:mm') : '-'),
            React.createElement(semantic_ui_react_1.Table.Cell, null, !(this.props.item.status === 'cancelled' || !this.props.item.originalScheduleDate || moment(this.props.item.originalScheduleDate).isBefore(moment().add(5, 'minutes'))) && React.createElement(semantic_ui_react_1.Button, { onClick: function (e) { return _this.cancelEmail(_this.props.item._id); } }, "Visszavon\u00E1s")),
            React.createElement(semantic_ui_react_1.Table.Cell, null, this.renderStatus()));
    };
    CustomNewsletterRow.prototype.renderStatus = function () {
        var sentStatusString = 'Feldolgozva';
        switch (this.props.item.status) {
            case 'scheduled':
                return React.createElement(semantic_ui_react_1.Label, { className: "CustomNewsletterStatusLabel scheduled" }, trans_1.trans('custom-newsletter.screen.list-item.status-scheduled'));
            case 'failed':
            case 'inprogress':
                return React.createElement(semantic_ui_react_1.Label, { className: "CustomNewsletterStatusLabel inprogress" }, trans_1.trans('custom-newsletter.screen.list-item.status-progress'));
            case 'sent':
                return React.createElement(semantic_ui_react_1.Label, { className: "CustomNewsletterStatusLabel sent" }, sentStatusString);
            default:
                return React.createElement(semantic_ui_react_1.Label, { className: "CustomNewsletterStatusLabel draft" }, trans_1.trans('custom-newsletter.screen.list-item.status-draft'));
        }
    };
    return CustomNewsletterRow;
}(responsive_component_1.ResponsiveComponent));
exports.CustomNewsletterRow = CustomNewsletterRow;
